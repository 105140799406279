main {
    padding: 1rem;
    max-width: 1000px;
    margin-inline: auto;
}

.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
}

.card {
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 8px 4px #0001;
    position: relative;
    overflow: hidden;
    transition: .3s;
}

.card>a:has(p) {
    color: inherit;
    text-decoration: none;
}

.card:hover {
    box-shadow: 2px 2px 12px 8px #0002;
}

.card::after {
    position: absolute;
    inset: auto 0 0 0;
    background: linear-gradient(to right, #7abdff, #00538b, #7abdff);
    background-size: 1000px;
    content: " ";
    height: 5px;
    flex: 1;
    width: 100%;
}

.card:hover::after {
    animation: card_hover 1s linear 0s infinite forwards;
}

@keyframes card_hover {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: 1000px;
    }
}

:is(p, h3)>a,
a:has(h3) {
    font-weight: 700;
    margin-left: 10px;
    text-decoration: none;
    color: dodgerblue;
    transition: .3s;
}

:is(p, h3)>a:hover,
.card:hover > a:has(h3) {
    color: #2a52be;
    text-decoration: underline;
}