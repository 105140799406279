header {
    background-color: #fff;
    box-shadow: 0 4px 4px 2px #0001;
}
header div {
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
    max-width: 1000px;
    margin-inline: auto;
}
header img {
    height: 50px;
}
header span {
    font-size: 30px;
    font-weight: 900;
}